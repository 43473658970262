import { compose, createStore, applyMiddleware} from "redux";
import rootReducer from "./reducers";
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { settingsActions } from './actions';

const loggerMiddleware = createLogger();

//const HIDE_REDUX_STORE = `${process.env.REACT_APP_HIDE_REDUX_STORE}`;

export default function configureStore(preloadedState = {}) {
  
  const composeEnhancers = (
    typeof global.window !== 'undefined' && 
    global.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) || compose;

  const composer = composeEnhancers(
    applyMiddleware(loggerMiddleware, thunkMiddleware)
  );

  const store = createStore(
    rootReducer,
    preloadedState,
    composer
  );

  return store;

};
