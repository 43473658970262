import React from 'react';
import { capitalizeFirstLetter } from '../../helpers/generalUtils';

function BasicSettings(props) {

    function handleChange(e) {
        var { name, value } = e.target;

        // Update parent's state
        props.onChange({[name]: value}, 'prompts');
    }

    return (
        <>
            <h4 className="text-primary">Basic Form Fields</h4>
            <hr />
            <div className="row no-gutters pb-1">
                <div className="col-3">
                    <strong>CRM Display Name</strong>
                </div>
                <div className="col-9">
                    <strong>Prompt</strong>
                </div>
            </div>
            <hr />

            {Object.entries(props.prompts).map(( [k, v] ) => 
                <>
                <div key={k} className="row no-gutters py-1">
                    <div className="col-3 mt-2">
                        {capitalizeFirstLetter(k.replace(/([a-z](?=[A-Z]))/g, '$1 '))}
                    </div>
                    <div className="col-9">
                        <input name={k} type="text" value={v} id={k} onChange={handleChange} className="form-control" />
                    </div>
                </div>
                <hr />
                </>)
            }
        </>
    );
}

export { BasicSettings }