import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../actions';
import { errorConstants } from '../constants/error.constants';
import Skeleton from 'react-loading-skeleton';

function Login(props) {
    
    const [inputs, setInputs] = useState({
        username: '',
        password: '',
        rememberMe: false
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password, rememberMe } = inputs;
    const alert = useSelector(state => state.alert);
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const generalSettings = useSelector(state => state.generalSettings);
    const loginSettings = useSelector(state => state.loginSettings);
    const dispatch = useDispatch();

    function handleChange(e) {
        if (alert.type && ["username", "password"].includes(e.target.name))
            dispatch(alertActions.clear());

        var { name, value } = e.target;

        if (e.target.type == "checkbox")
            value = e.target.checked;

        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);

        if (username && password) {
            dispatch(userActions.login(username, password, rememberMe));
        }
    }

    return (
            <React.Fragment>
                <div className="content-box">
                    <Skeleton className="d-none d-md-inline-flex bg-primary rounded-0 loading-bar" style={{visibility: loggingIn ? 'visible' : 'hidden'}} />

                    <div className="p-4">
                        <div className="text-center mb-4">
                            <img src={generalSettings.images.logo} style={generalSettings.images.logoWidth ? {width: generalSettings.images.logoWidth} : {}} />
                            <h3 className="mt-2">{loginSettings.showTitle && loginSettings.title}</h3>
                            {loginSettings.showSubtitle && <h5>{loginSettings.subtitle}</h5>}
                        </div>
                        {loginSettings.instructionsText && <p>{loginSettings.instructionsText}</p>}
                        {alert.type &&
                            <div className="text-danger my-3">{alert.message != errorConstants.UNAUTHORIZED ? "An unexpected error has occurred." : loginSettings.messages.authenticationError}</div>
                        }
                        <form name="form" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <input type="text" placeholder={loginSettings.prompts.webLoginName} name="username" value={username} onChange={handleChange} className={'form-control' + (submitted && !username ? ' is-invalid' : '')} />
                                {submitted && !username &&
                                    <div className="invalid-feedback">{loginSettings.messages.userNameRequired}</div>
                                }
                            </div>
                            <div className="mb-3">
                                <input type="password" placeholder={loginSettings.prompts.webLoginPassword} name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                                {submitted && !password &&
                                    <div className="invalid-feedback">{loginSettings.messages.passwordRequired}</div>
                                }
                            </div>
                            <div className="mb-3">
                                {loginSettings.instructions.signInHelp && <p>{loginSettings.instructions.signInHelp}</p>}
                                {loginSettings.formTexts.signInHelpLink && <a href={loginSettings.urls.signInHelp} className="text-decoration-none">{loginSettings.formTexts.signInHelpLink}</a>}
                            </div>
                            {loginSettings.options.displayRememberMeOnThisComputer &&
                            <div className="mb-3">
                                <div className="form-check form-switch">
                                    <input className={"form-check-input" + (rememberMe ? " bg-primary border-primary" : "")} type="checkbox" id="rememberMe" name="rememberMe" checked={rememberMe} onChange={handleChange}></input>
                                    <label className="form-check-label" htmlFor="rememberMe">{loginSettings.formTexts.rememberMeOnThisComputer}</label>
                                </div> 			
                            </div>
                            }
                            <div>
                                <div className="d-grid gap-2">
                                    <button className="btn btn-primary" disabled={loggingIn}>
                                        {!loggingIn ? loginSettings.formTexts.signInButton : loginSettings.formTexts.signingInButton}
                                    </button>
                                </div>
                                {loginSettings.formTexts.createNewUserLink &&
                                <div className="mt-3">
                                    <a href={loginSettings.urls.createNewUser} className="text-decoration-none">{loginSettings.formTexts.createNewUserLink}</a>
                                </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>   
    );
}

export { Login };