import { userConstants } from '../constants';
import { authenticationStateModel } from './stateModels';

const initialState = authenticationStateModel;

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggingIn: false,
                loggedIn: true
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggingIn: false,
                loggedIn: false,
                user: {}
            };
        case userConstants.LOGOUT:
            return {
                loggedIn: false,
                user: {}
            };
        default:
            return state;
    }
}