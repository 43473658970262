import React from 'react';

function ThemesMainNav(props) {

    function handleClick(level2) { 
        props.onClick(level2);
    }

    return (
        <>
        <ul className="nav nav-pills border-bottom" role="tablist">
            <li className="nav-item text-center">
                <a role="button" onClick={() => handleClick('site')} className={"nav-link " + (props.active == 'site' ? 'active' : '')}>
                    <i className="fas fa-globe fa-2x pt-2 d-block"></i><small className="mb-3">Site</small>
                </a>
            </li>
            <li className="nav-item text-center">
                <a role="button" onClick={() => handleClick('login')} className={"nav-link " + (props.active == 'login' ? 'active' : '')}>
                    <i className="fas fa-sign-in-alt fa-2x pt-2 d-block"></i><small className="mb-3">Login</small>
                </a>
            </li>
            <li className="nav-item text-center">
                <a role="button" onClick={() => handleClick('about')} className={"nav-link " + (props.active == 'about' ? 'active' : '')}>
                    <i className="fas fa-question fa-2x pt-2 d-block"></i><small className="mb-3">About</small>
                </a>
            </li>
        </ul>
        </>
    )
}

export { ThemesMainNav };
