import React from 'react';
import { Fieldset } from './Fieldset';
import { previewUrl } from '../../helpers/generalUtils';

function Login(props) {
    function handleChange(e, fieldset) {
        var { name, value } = e.target;

        if (e.target.type == "checkbox")
            value = e.target.checked;

        // Update parent's state
        props.onChange({[name]: value}, fieldset);
    }    

    return (
        <>
        <h4 className="text-primary">Advanced Settings</h4>
        <hr />
        <Fieldset title="Options">
            <div className="mb-3">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="displayRememberMeOnThisComputer" name="displayRememberMeOnThisComputer" checked={props.settings.options.displayRememberMeOnThisComputer} onChange={(e) => handleChange(e, 'options')}></input>
                    <label className="form-check-label" htmlFor="displayRememberMeOnThisComputer">Display remember me on this computer</label>
                </div>
            </div>
        </Fieldset>
        <Fieldset title="Urls">
            <>
            <div className="mb-3">
                <label htmlFor="signInHelp" className="form-label">Sign in help link URL</label>
                <div className="input-group">
                    <input type="text" className="form-control" name="signInHelp" id="signInHelp" value={props.settings.urls.signInHelp} onChange={(e) => handleChange(e, 'urls')} />
                    <div className="input-group-text">
                        <button onClick={() => previewUrl(props.settings.urls.signInHelp)} className="btn btn-sm" type="button"><i className="fas fa-globe" /></button>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="createNewUser" className="form-label">Create new user link URL</label>
                <div className="input-group">
                    <input type="text" className="form-control" name="createNewUser" id="createNewUser" value={props.settings.urls.createNewUser} onChange={(e) => handleChange(e, 'urls')} />
                    <div className="input-group-text">
                        <button onClick={() => previewUrl(props.settings.urls.createNewUser)} className="btn btn-sm" type="button"><i className="fas fa-globe" /></button>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="changePassword" className="form-label">Change password URL</label>
                <div className="input-group">
                    <input type="text" className="form-control" name="changePassword" id="changePassword" value={props.settings.urls.changePassword} onChange={(e) => handleChange(e, 'urls')} />
                    <div className="input-group-text">
                        <button onClick={() => previewUrl(props.settings.urls.changePassword)} className="btn btn-sm" type="button"><i className="fas fa-globe" /></button>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="signOut" className="form-label">Sign out link URL</label>
                <div className="input-group">
                    <input type="text" className="form-control" name="signOut" id="signOut" value={props.settings.urls.signOut} onChange={(e) => handleChange(e, 'urls')} />
                    <div className="input-group-text">
                        <button onClick={() => previewUrl(props.settings.urls.signOut)} className="btn btn-sm" type="button"><i className="fas fa-globe" /></button>
                    </div>
                </div>
            </div>
            </>
        </Fieldset>
        <Fieldset title="Instructions">
            <div className="mb-3">
                <label htmlFor="signInHelpInstructions" className="form-label">Sign in help instructions</label>
                <textarea className="form-control" name="signInHelp" id="signInHelpInstructions" value={props.settings.instructions.signInHelp} onChange={(e) => handleChange(e, 'instructions')} />
            </div>
        </Fieldset>
        <Fieldset title="Form Text">
            <>
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="signInHelpLink" className="form-label">Sign in help link text</label>
                    <input type="text" className="form-control" name="signInHelpLink" id="signInHelpLink" value={props.settings.formTexts.signInHelpLink} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
                <div className="col-6">
                    <label htmlFor="createNewUserLink" className="form-label">Create new user link text</label>
                    <input type="text" className="form-control" name="createNewUserLink" id="createNewUserLink" value={props.settings.formTexts.createNewUserLink} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div>     
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="signInButton" className="form-label">Sign in button text</label>
                    <input type="text" className="form-control" name="signInButton" id="signInButton" value={props.settings.formTexts.signInButton} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
                <div className="col-6">
                    <label htmlFor="signingInButton" className="form-label">Signing in button text</label>
                    <input type="text" className="form-control" name="signingInButton" id="signingInButton" value={props.settings.formTexts.signingInButton} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div> 
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="rememberMeOnThisComputer" className="form-label">Remember me on this computer text</label>
                    <input type="text" className="form-control" name="rememberMeOnThisComputer" id="rememberMeOnThisComputer" value={props.settings.formTexts.rememberMeOnThisComputer} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
                <div className="col-6">
                    <label htmlFor="changePasswordLink" className="form-label">Change password text</label>
                    <input type="text" className="form-control" name="changePasswordLink" id="changePasswordLink" value={props.settings.formTexts.changePasswordLink} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div>  
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="signOutButton" className="form-label">Sign out text</label>
                    <input type="text" className="form-control" name="signOutButton" id="signOutButton" value={props.settings.formTexts.signOutButton} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
                <div className="col-6">
                    <label htmlFor="signingOutButton" className="form-label">Signing out text</label>
                    <input type="text" className="form-control" name="signingOutButton" id="signingOutButton" value={props.settings.formTexts.signingOutButton} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div>      
            </>
        </Fieldset>
        <Fieldset title="Messages">
            <>
            <div className="mb-3">
                <label htmlFor="userNameRequired" className="form-label">User name required</label>
                <textarea className="form-control" name="userNameRequired" id="userNameRequired" value={props.settings.messages.userNameRequired} onChange={(e) => handleChange(e, 'messages')} />
            </div>
            <div className="mb-3">
                <label htmlFor="passwordRequired" className="form-label">Password required</label>
                <textarea className="form-control" name="passwordRequired" id="passwordRequired" value={props.settings.messages.passwordRequired} onChange={(e) => handleChange(e, 'messages')} />
            </div>
            <div className="mb-3">
                <label htmlFor="authenticationError" className="form-label">Authentication error</label>
                <textarea className="form-control" name="authenticationError" id="authenticationError" value={props.settings.messages.authenticationError} onChange={(e) => handleChange(e, 'messages')} />
            </div>
            </>
        </Fieldset>
        </>
    );
}

export { Login };