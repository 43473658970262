/**
 * 
 * @param {Object} store Redux Store Instance
 * @returns A JSON with the user Information
 */
export const getAuthenticationInfo = (store) => {
    return store.getState().authentication;
};

/**
 * 
 * @param {Object} store Redux Store Instance
 * @returns If the user is logged in
 */
export const isLoggedInUser = (store) => {
    if (store){
      var state = store.getState();
      if(state){
        var authentication = state.authentication;
        if(authentication){
          return authentication.loggedIn;
        } else {
          return false;  
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
};