import {http} from "../api/http";

export const samlSimulationService = {
  login
};


function login(applicationName, contactId, idpInitiated) {
  
  let url = !idpInitiated ? 
            `/api/sp-simulation/login/${applicationName}/${contactId}` : 
            `/api/idp-simulation/sso/${applicationName}/${contactId}?idpinitiated=true`;

  return http.get(url)
  .then(response => {
    return response;
  }
  ,
  error => {
    return error.toString();
  }); 
};