export const errorConstants = {    
    DEFAULT_CODE: "Default",
    DESCRIPTION_NOT_FOUND : "DescriptionNotFound",    
    UNAUTHORIZED: "UNAUTHORIZED",
    FORBIDDEN: "FORBIDDEN",
    NOT_FOUND: "NOT_FOUND",
    REQUEST_TIMEOUT: "REQUEST_TIMEOUT",    
    SERVICE_UNAVAILABLE: "SERVICE_UNAVAILABLE",
    SERVER_RESPONSE_TIMEOUT: "SERVER_RESPONSE_TIMEOUT",
    USER_NOT_LOGGED_OUT: "UserNotLoggedOut",
    USER_SET_ERROR: "UserSetError",
    USER_UNSET_ERROR: "UserUnsetError"
};