import React from 'react';
import { ImageUploader } from './ImageUploader';

function ThemesSiteImages(props) {

    return (
            <>
            {props.settings &&
            <>
            <h4 className="text-primary">Images</h4>
            <hr />
            <div className="row mb-3">
                <div className="col">
                    Logo
                    <ImageUploader 
                        imageUrl={props.settings.images.logo} 
                        onImageSourceChange={(imageSource) => props.onImageSourceChange(imageSource, 'logo')}
                        onImageUpload={(commitToken, previewUrl) => props.onImageUpload(props.theme, commitToken, previewUrl, 'logo')}
                        onImageUrlChange={(imageUrl) => props.onImageUrlChange(imageUrl, 'logo')}
                        width={props.settings.images.logoWidth}
                        theme={props.theme}
                        timestampVersion={props.timestampVersion}>
                    </ImageUploader>
                </div>
                <div className="col">                                
                    Favicon
                    <ImageUploader 
                        imageUrl={props.settings.images.favicon} 
                        onImageSourceChange={(imageSource) => props.onImageSourceChange(imageSource, 'favicon')}
                        onImageUpload={(commitToken, previewUrl) => props.onImageUpload(props.theme, commitToken, previewUrl, 'favicon')}
                        onImageUrlChange={(imageUrl) => props.onImageUrlChange(imageUrl, 'favicon')}
                        theme={props.theme}
                        timestampVersion={props.timestampVersion}>
                    </ImageUploader>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="logoWidth" className="form-label">Logo Width</label>
                    <input value={props.settings.images.logoWidth} onChange={(e) => props.onLogoWidthChange(e.target.value)} type="text" className="form-control" name="logoWidth" id="logoWidth" />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6">
                    Background
                    <ImageUploader 
                        imageUrl={props.settings.images.background} 
                        onImageSourceChange={(imageSource) => props.onImageSourceChange(imageSource, 'background')}
                        onImageUpload={(commitToken, previewUrl) => props.onImageUpload(props.theme, commitToken, previewUrl, 'background')}
                        onImageUrlChange={(imageUrl) => props.onImageUrlChange(imageUrl, 'background')}
                        theme={props.theme}
                        displayNoImage={true}
                        timestampVersion={props.timestampVersion}>
                    </ImageUploader>
                </div>                
            </div>
            </>
            }
            </>
    )
}

export { ThemesSiteImages };
