import {http} from "../api/http";

export const assetsService = {
  commit,
  del,
  copy
};


function commit(commitToken) {
  
  return http.put("/api/assets/commit", JSON.stringify({ commitToken }))
    .then(response => {
      return response;
    });
};

function del(fileName) {
  
  return http.delete("/api/assets/delete?fileName=" + fileName)
    .then(response => {
      return response;
    });
}

function copy(copyRequest) {
  
  return http.post("/api/assets/copy", JSON.stringify(copyRequest))
    .then(response => {
      return response;
    });
}