import {http} from "../api/http";

export const settingsService = {
  get,
  update,
  themeDefaultValues
};

function get() {
  
  return http.get("/api/settings")
    .then(response => {
      return response;
    });
};

function update(settings) { 

  return http.post("/api/settings/update", JSON.stringify(settings))
    .then(response => {
      return response;
    });
};

function themeDefaultValues() {
  
  return http.get("/api/settings/themedefaultvalues")
    .then(response => {
      return response;
    });
};