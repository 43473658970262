import React from 'react';

function UnsavedChangesModal(props) {
    return (
        <>
            <div className="fade modal-backdrop show"></div>
            <div role="dialog" className="fade modal show" tabIndex="-1" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-3">Are you sure you want to leave the page?</h5>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                You have unsaved changes.
                            </div>
                        </div>
                        <div className="modal-footer text-end">
                            <button onClick={props.onDiscardChanges} className="btn btn-primary me-2">Leave</button>
                            <button onClick={props.onStay} className="btn btn-secondary">Stay</button>
                        </div>
                    </div>
                </div>
            </div>     
        </>
    )
}

export { UnsavedChangesModal };
