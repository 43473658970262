import React, {useState } from 'react';
import { spSimulationActions, tenantInfoActions } from '../../actions';
import  { errorConstants } from '../../constants';
import beautify from "xml-beautifier";

function ApplicationTest(props) {    
    const [inputs, setInputs] = useState({
        contact: '',
        includeSAMLRequest: true
    });
    const [contactNotFound, setContactNotFound] = useState(false);
    const [testError, setTestError] = useState(false);    
    const [isLoading, setIsLoading] = useState(false); 

    function handleInputChange(e) {
        var { name, value } = e.target;

        if (e.target.type == "checkbox")
            value = e.target.checked;

        setInputs(inputs => ({ ...inputs, [name]: value }));
        setContactNotFound(false);
    }

    function handleTestClick(e) {
        setIsLoading(true);
        setTestError(false);

        if (!inputs.contact)
        {
            handleContactNotFound();
            return;
        }

        tenantInfoActions.getContactId(inputs.contact).then(
            contactId => {
                if (contactId === errorConstants.SERVER_RESPONSE_TIMEOUT) // Really a 404 Not Found error
                    handleContactNotFound();
                else
                {
                    let ssoEndpointArray = props.application.ssoEndpoint.split('/');

                    spSimulationActions.login(ssoEndpointArray[ssoEndpointArray.length - 1], contactId, !inputs.includeSAMLRequest).then(loginSimulation => {
                        if (loginSimulation)
                            setInputs(inputs => ({ ...inputs, request: loginSimulation.SAMLRequest ? beautify(loginSimulation.SAMLRequest) : '', response: beautify(loginSimulation.SAMLResponse) }));
                        else {
                            setTestError(true);
                            setInputs({ ...inputs, response: '', request: '' });
                        }

                        setIsLoading(false);
                    });
                }
        });
    }

    function handleContactNotFound() {
        setTestError(false);
        setIsLoading(false);
        setContactNotFound(true);
        setInputs({ ...inputs, response: '', request: '' });
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') 
        {
            handleTestClick(e);
            e.target.blur();
        }
    }

    return (
        <>
        {props.unsavedChanges && <div className="alert alert-warning">Save the latest changes before executing the test.</div>}
        <div className="mb-3">
            <label htmlFor="contact" className="form-label">User Number or User Web Login Name</label>
            <div className="input-group">
                <input type="text" onChange={handleInputChange} onKeyDown={handleKeyDown} value={inputs.contact} id="contact" name="contact" className="form-control" />
                <div class="input-group-text">
                    <input type="checkbox" onChange={handleInputChange} checked={inputs.includeSAMLRequest} className="form-check-input mt-0" id="includeSAMLRequest" name="includeSAMLRequest" />
                    <label htmlFor="includeSAMLRequest" className="ms-2">Include SAML Request</label>
                </div>
                <button onClick={handleTestClick} disabled={isLoading} className="btn btn-primary px-4">
                    <i className="fas fa-play me-2"></i>Execute
                </button>
            </div>
        </div>

        {isLoading && 
            <div class="text-center fa-3x mt-5">
                <i className="fas fa-spinner fa-spin"></i>
            </div>
        }

        {!isLoading && 
        <>
            {contactNotFound && 
            <div className="text-center mt-5">
                <h5 className="fw-bold">User was not found.</h5>
                <h6>Please try again with a different User.</h6>
            </div>
            }        

            {testError && <div className="alert alert-danger">An error has occurred while executing the test.</div>}

            {inputs.response &&
            <>
            <div className="mb-3">
                <label htmlFor="request" className="form-label">Request</label>
                <textarea name="request" disabled className="form-control" rows="10" value={inputs.request}></textarea>
            </div>

            <div className="mb-3">
                <label htmlFor="response" className="form-label">Response</label>
                <textarea name="response" disabled className="form-control" rows="10" value={inputs.response}></textarea>
            </div>
            </>
            }
        </>
        }
        </>
    )
}

export { ApplicationTest };
