import React from 'react';

function General(props) {

    function handleChange(e, level2) { 
        let value = e.target.value;
        let key = e.target.name;
        
        if (e.target.type == "checkbox") value = e.target.checked;

        props.onChange('site', level2, key, value);
    }

    function handleChangeMfa(e) { 
        let value = e.target.value;
        let mfaType = e.target.name.split('_')[0];
        let key = e.target.name.split('_')[1];
        
        if (e.target.type == "checkbox") value = e.target.checked;

        props.onMfaChange(mfaType, key, value);
    }

    return (
        <>
            {props.settings &&
            <>
            <h4 className="text-primary">General</h4>
            <hr />
            <h5 className="text-primary">Authentication</h5>
            <div className="row mb-3">
                <div className="col">
                    <label htmlFor="timeout" className="form-label">Timeout</label>
                    <input type="text" className="form-control" name="timeout" id="timeout" value={props.settings.authentication.timeout} onChange={(e) => handleChange(e, 'authentication')} />
                </div>
            </div>
            <hr className="d-none" />
            <h5 className="text-primary d-none">Two-factor authentication</h5>
            <div className="row mb-3 d-none">
                <div className="col">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" name="verificationCode_enabled" id="verificationCode_enabled" checked={props.settings.mfa?.find(a => a.type == 'verificationCode')?.enabled} onChange={(e) => handleChangeMfa(e)} />
                        <label className="form-check-label" htmlFor="verificationCode_enabled">Enabled</label>
                    </div>
                </div>
            </div>
            {props.settings.mfa?.find(a => a.type == 'verificationCode')?.enabled && 
            <>
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="verificationCode_fromName" className="form-label">From Name</label>
                    <input type="text" className="form-control" name="verificationCode_fromName" id="verificationCode_fromName" value={props.settings.mfa?.find(a => a.type == 'verificationCode')?.fromName} onChange={(e) => handleChangeMfa(e)} />
                </div>
                <div className="col-6">
                    <label htmlFor="verificationCode_fromEmail" className="form-label">From Email</label>
                    <input type="text" className="form-control" name="verificationCode_fromEmail" id="verificationCode_fromEmail" value={props.settings.mfa?.find(a => a.type == 'verificationCode')?.fromEmail} onChange={(e) => handleChangeMfa(e)} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="verificationCode_subject" className="form-label">Subject</label>
                    <input type="text" className="form-control" name="verificationCode_subject" id="verificationCode_subject" value={props.settings.mfa?.find(a => a.type == 'verificationCode')?.subject} onChange={(e) => handleChangeMfa(e)} />
                </div>
            </div>
            </>
            }
            <hr />
            <h5 className="text-primary">Appearance</h5>
            <div className="mb-3">
                <label htmlFor="theme" className="form-label">Theme</label>
                <select value={props.settings.theme} onChange={(e) => handleChange(e)} id="theme" name="theme" className="form-select">
                {props.themes.map((theme) => { return <option value={theme.id}>{theme.name}</option> })}        
                </select>
            </div>
            <hr />
            </>
            }
        </>
    )
}

export { General };
