import React from 'react';

function SSOMainNav(props) {

    function handleClick(level3) { 
        props.onClick(level3);
    }

    return (
        <>
        <ul className="nav nav-pills border-bottom mb-3" role="tablist">
            <li className="nav-item text-center">
                <a role="button" onClick={() => handleClick('applications')} className={"nav-link " + (props.active == 'applications' ? 'active' : '')}>
                    <i className="fas fa-window-maximize fa-2x pt-2 d-block"></i><small className="mb-3">Applications</small>
                </a>
            </li>
            <li className="nav-item text-center">
                <a role="button" onClick={() => handleClick('token')} className={"nav-link " + (props.active == 'token' ? 'active' : '')}>
                    <i className="fas fa-lock fa-2x pt-2 d-block"></i><small className="mb-3">Token</small>
                </a>
            </li>
            <li className="nav-item text-center">
                <a role="button" onClick={() => handleClick('saml')} className={"nav-link " + (props.active == 'saml' ? 'active' : '')}>
                    <i className="fas fa-key fa-2x pt-2 d-block"></i><small className="mb-3">SAML</small>
                </a>
            </li>
        </ul>
        </>
    )
}

export { SSOMainNav };
