import { assetsService } from '../services';

export const assetsActions = {
    commit,
    del,
    copy
};

function commit(commitToken) {

       return assetsService.commit(commitToken)
            .then(
                response => { return response.data; },
                error => { return error.toString(); }
            );
};

function del(fileName) {

    return assetsService.del(fileName)
         .then(
             response => { return response.data; },
             error => { return error.toString(); }
         );
};


function copy(copyRequest) {

    return assetsService.copy(copyRequest)
         .then(
             response => { return response.data; },
             error => { return error.toString(); }
         );
};
