import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

function ConfigurationModal() {

    const [showModal, setShowModal] = useState(false);
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        handleResize();        
    }, []);

    function handleOpenClick(e) {
        setShowModal(true);
    }

    function handleResize() {
        setDimensions({
            width: window.innerWidth * 0.98,
            height: window.innerHeight,
        });
    }

    return (
        <>
            <a role="button" onClick={handleOpenClick} className="text-decoration-none">
                <i className="fas fa-cog me-1"></i>Configuration
            </a>
            <Modal className="admin-modal" show={showModal} backdrop="static" keyboard={false} enforceFocus={false} scrollable={true}
            style={{width: dimensions.width + "px", height: dimensions.height + "px"}} contentClassName="border-0">
                <iframe src='/admin' style={{height: dimensions.height + "px"}}></iframe>                
            </Modal>
        </>
    );
}

export { ConfigurationModal };