import React from 'react';

function ThemesPagesNav(props) {

    function handleLevel3Click(level3) { 
        props.onLevel3Click(level3);
    }

    return (
        <ul className="nav sticky-top bg-primary p-2 mb-3">
            {props.displaySecondLevelIcon && <i className={props.secondLevelIcon + " fa-lg text-light mt-1 p-2"}></i>}
            <li className={"nav-item " + (props.level3 == "global" ? "border-bottom" : "")}>
                <a role="button" onClick={() => handleLevel3Click('global')} className="nav-link text-white" aria-current="page">Global</a>
            </li>
            <li className={"nav-item " + (props.level3 == "basic" ? "border-bottom" : "")}>
                <a role="button" onClick={() => handleLevel3Click('basic')} className="nav-link text-white" aria-current="page">Basic Form Fields</a>
            </li>
            <li className={"nav-item " + (props.level3 == "advanced" ? "border-bottom" : "")}>
                <a role="button" onClick={() => handleLevel3Click('advanced')} className="nav-link text-white">Advanced Settings</a>
            </li>
        </ul>
    )
}

export { ThemesPagesNav };
