import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { generalSettings } from './generalSettings.reducer';
import { loginSettings } from './loginSettings.reducer';

export default combineReducers({
  authentication,
  alert,
  generalSettings,
  loginSettings
})
