
export const isDefaultTheme = (themeId, settings)  => {
  if (settings.site.theme == themeId) return true;
  return false;
}

export const isThemeInUse = (themeId, settings) => {
  return themeUses(themeId, settings).length > 0;
}

export const themeUses = (themeId, settings) => {
  let uses = [];

  // Check if theme is the default theme
  if (isDefaultTheme(themeId, settings)) uses.push('Site');

  // Add Saml application using this theme
  settings.site.sso.applications.filter(app => app.theme == themeId).map(app => uses.push('SSO Integrations > Application > ' + app.name));

  return uses;
}