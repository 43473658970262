// ** 
// To keep the code cleaned, we put sub action creators into nested functions 
// within each async action creator function. 
// We can see the login() function contains 3 nested action creator functions 
// for request(), success() and failure() that return the actions LOGIN_REQUEST, 
// LOGIN_SUCCESS and LOGIN_FAILURE respectively. 
// Putting the sub action creators into nested functions also allows us to give 
// them standard names like request, success and failure without clashing with 
// other function names because they only exist within the scope of the parent 
// function.
// **

import { userConstants, errorConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';

export const userActions = {
    login,
    logout
};

function login(username, password, rememberMe) {
    return dispatch => {
        
        dispatch(request({ username }));

        userService.login(username, password, rememberMe)
            .then(
                response => { 
                    dispatch(success());

                    // Redirect to an external url or Refresh page
                    window.location.href = (response.data ? response.data : window.location.href);
                },
                error => {
                    dispatch(failure(error.toString()));
                    
                    if (error == errorConstants.UNAUTHORIZED) {
                        dispatch(alertActions.info(errorConstants.UNAUTHORIZED));
                    } else {
                        dispatch(alertActions.error(error));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success() { return { type: userConstants.LOGIN_SUCCESS } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    return dispatch => {
        userService.logout()
        .then(
            response => {   
                dispatch({ type: userConstants.LOGOUT });
                window.location.href = window.location.href
            }
        );
    }
}