import {http} from "../api/http";

export const tenantInfoService = {
  getCustomer,
  getCrm,
  getUrls,
  getSamlCertificate,
  getCrmSchema,
  getContactId,
  getApplicationSPMetadata
};

function getCustomer() { 

  return http.get("/api/tenantInfo/customer")
    .then(response => {
      return response;
    });
};

function getCrm() { 

  return http.get("/api/tenantInfo/crm")
    .then(response => {
      return response;
    });
};

function getUrls() { 

  return http.get("/api/tenantInfo/urls")
    .then(response => {
      return response;
    });
};

function getSamlCertificate() {
  
  return http.get("/api/tenantInfo/samlcertificate")
    .then(response => {
      return response;
    });
};

function getCrmSchema(entityNames) {

  let query = '';
  for (const entityName of entityNames)
    query += (!query ? '?' : '&') + "entityNames=" + entityName;
  
  return http.get("/api/tenantInfo/crmschema" + query)
    .then(response => {
      return response;
    });
};

function getContactId(contactNumber) {
  
  return http.get("/api/tenantInfo/contactid?contactNumber=" + contactNumber)
    .then(response => {
      return response;
    });
};

function getApplicationSPMetadata(url) {

  return http.get("/api/tenantInfo/application-sp-metadata?url=" + url)
    .then(response => {
      return response;
    });
}