import {http} from "../api/http";

export const idpService = {
  metadata
};


function metadata(applicationName) {
  
  return http.get(`/api/idp/metadata/${applicationName}`)
    .then(response => {
      return response;
    });
};
