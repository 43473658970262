import { settingsService } from '../services';

export const settingsActions = {
    get,
    update,
    themeDefaultValues
};

function get() {
    return settingsService.get()
        .then(
            settings => {                     
                return settings.data;
            },
            error => {
                return error.toString();
            }
        );
};

function update(settings, userFullName) {
    // Include last modified info
    settings.lastModifiedOn = new Date(Date.now()).toLocaleString('en-US');
    settings.lastModifiedBy = userFullName;

    return settingsService.update(settings)
        .then(
            s => {   
                return s;
            }
        );
};

function themeDefaultValues() {
    return settingsService.themeDefaultValues()
        .then(
            defaultValues => {                     
                return defaultValues.data;
            },
            error => {
                return error.toString();
            }
        );
};
