import React from 'react';

function TokenIntegration(props) {

    function handleChange(e) { 
        let value = e.target.value;
        let key = e.target.name;

        if (e.target.type == "checkbox") value = e.target.checked;

        props.onChange('site', 'sso', 'token', key, value);
    }

    return (
        <>
            {props.settings && 
            <>
            <h4 className="text-primary">Token Method</h4>
            <hr />
            <div className="mb-3">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="integrationEnabled" name="integrationEnabled" checked={props.settings.integrationEnabled} onChange={(e) => handleChange(e)}></input>
                    <label className="form-check-label" htmlFor="integrationEnabled">Integration enabled</label>
                </div> 
            </div>
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="cookieName" className="form-label">Cookie Name</label>
                    <input type="text" className="form-control" name="cookieName" id="cookieName" value={props.settings.cookieName} onChange={(e) => handleChange(e)} />
                </div>
                <div className="col-6">
                    <label htmlFor="cookieDomain" className="form-label">Cookie Domain</label>
                    <input type="text" className="form-control" name="cookieDomain" id="cookieDomain" value={props.settings.cookieDomain} onChange={(e) => handleChange(e)} />
                </div>
            </div>
            </>
            }
        </>
    )
}

export { TokenIntegration };
