import { errorConstants } from "../constants";

const STATUS_BAD_REQUESTS = 400;
const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;
const STATUS_NOT_FOUND = 404;
const STATUS_REQUEST_TIMEOUT = 408;
const STATUS_PRECONDITION_FAILED = 412;
const STATUS_SERVER_ERROR = 500;
const STATUS_BAD_GATEWAY = 502;
const STATUS_SERVICE_UNAVAILABLE = 503;

export const getStatusAndMessage = (response) => {
    let res = `(${response.StatusCode}) `;
    if (response.Error) {
        res += response.Error;
    } 
    
    return res
}

export const errorNoServerResponse = () => {
    return Promise.reject(errorConstants.SERVER_RESPONSE_TIMEOUT);
}

export const checkStatusCode = (status, responseData) => {

    let res;
    switch(status) {
        case STATUS_SERVER_ERROR:
            res = responseData.Error ? responseData.Error : getStatusAndMessage(responseData)
            break;
        case STATUS_BAD_REQUESTS:
            res = responseData.Error ? responseData.Error : getStatusAndMessage(responseData)
            break;
        case STATUS_BAD_GATEWAY:
            res = errorConstants.CONNECTION_LOST_CODE;
            break;
        case STATUS_UNAUTHORIZED:
            res = errorConstants.UNAUTHORIZED;
            break;
        case STATUS_FORBIDDEN:
            res = errorConstants.FORBIDDEN;
            break;
        case STATUS_NOT_FOUND:
            res = errorConstants.NOT_FOUND;
            break;
        case STATUS_REQUEST_TIMEOUT:
            res = errorConstants.REQUEST_TIMEOUT;
            break;
        case STATUS_PRECONDITION_FAILED:
            res = errorConstants.PRECONDITION_FAILED;
            break;
        case STATUS_SERVICE_UNAVAILABLE:
            res = errorConstants.SERVICE_UNAVAILABLE;
            break;
        default:
            res = getStatusAndMessage(responseData);
            break;
    }
    return Promise.reject(res);
}