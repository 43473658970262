import { http } from "../api/http";
import { getParams } from '../helpers/generalUtils';

export const userService = {
  login,
  logout,
};

function login(username, password, rememberMe) {
  
  var url = "/api/user/signin";

  if(getParams("response_mode") === "saml_form_post"){
    url =  `${url}?response_mode=saml_form_post`;
  }
  
  return http.post(url, JSON.stringify({ 
      username, 
      password,
      rememberMe
    }))
    .then(response => {
      return response;
    });
}

function logout() {

  return http.post("/api/user/signout")
    .then(response => {
      return response;
    });
}