import React from 'react';
import { previewUrl } from '../../helpers/generalUtils';
import Editor from "@monaco-editor/react";

function ThemesSiteHeader(props) {

    function handleChange(e) { 
        let value = e.target.value;
        let key = e.target.name;
        
        if (e.target.type == "checkbox") value = e.target.checked;

        props.onChange('site', 'header', key, value);
    }

    function handleHtmlChange(e) { 
        let value = e;
        let key = 'html';        

        props.onChange('site', 'header', key, value);
    }

    return (
            <>
                {props.settings &&
                <>
                <h4 className="text-primary">Header</h4>
                <hr />
                <div className="mb-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="display" name="display" checked={props.settings.header.display} onChange={(e) => handleChange(e)}></input>
                        <label className="form-check-label" htmlFor="display">Display</label>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="homeUrl" className="form-label">Home Url</label>
                    <div className="input-group">                                    
                        <input type="text" className="form-control" name="homeUrl" id="homeUrl" value={props.settings.header.homeUrl} onChange={(e) => handleChange(e)} />
                        <div className="input-group-text">
                            <button onClick={() => previewUrl(props.settings.header.homeUrl)} className="btn btn-sm" type="button"><i className="fas fa-globe" /></button>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="html" className="form-label">Html</label>
                    <Editor
                        height="40vh"
                        defaultLanguage="html"
                        name="html" 
                        id="html"
                        value={props.settings.header.html} 
                        onChange={(e) => handleHtmlChange(e)}
                    />
                </div>
                </>
                }
            </>
    )
}

export { ThemesSiteHeader };
