import React, { useState } from 'react';

function Fieldset(props) {

    const [expanded, setExpanded] = useState(false);

    function handleExpand() {
        setExpanded(!expanded);
    }

    return (
        <>
            <a role="button" onClick={handleExpand} className="text-decoration-none">
                <h5 className="mb-3">{props.title} <i className="fas fa-chevron-down fa-sm"></i></h5>
            </a>
            <div className={"collapse " + (expanded ? "show" : "")}>
                {props.children}
            </div>
            <hr />
        </>
    )
}

export { Fieldset };
