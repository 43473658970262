import React from 'react';
import { previewUrl } from '../../helpers/generalUtils';
import Editor from "@monaco-editor/react";

function ThemesSiteStyling(props) {

    function handleChange(e) { 
        let value = e.target.value;
        let key = e.target.name;
        
        if (e.target.type === "checkbox") value = e.target.checked;

        props.onChange('site', 'styling', key, value);
    }

    function handleCssInlineChange(e) { 
        let value = e;
        let key = 'cssInline';        

        props.onChange('site', 'styling', key, value);
    }

    return (
            <>
                {props.settings &&
                <>
                <h4 className="text-primary">Styling</h4>
                <hr />
                <div className="mb-3">
                    <label htmlFor="cssInline" className="form-label">CSS Content</label>
                    <Editor
                        height="40vh"
                        defaultLanguage="css"
                        name="cssInline" 
                        id="cssInline"
                        value={props.settings.styling.cssInline} 
                        onChange={(e) => handleCssInlineChange(e)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="stylesheetUrl1" className="form-label">External Stylesheet 1</label>
                    <div className="input-group">
                        <input type="text" className="form-control" name="stylesheetUrl1" id="stylesheetUrl1" value={props.settings.styling.stylesheetUrl1} onChange={(e) => handleChange(e)} />
                        <div className="input-group-text">
                            <button onClick={() => previewUrl(props.settings.styling.stylesheetUrl1)} className="btn btn-sm" type="button"><i className="fas fa-globe" /></button>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="stylesheetUrl2" className="form-label">External Stylesheet 2</label>
                    <div className="input-group">
                        <input type="text" className="form-control" name="stylesheetUrl2" id="stylesheetUrl2" value={props.settings.styling.stylesheetUrl2} onChange={(e) => handleChange(e)} />
                        <div className="input-group-text">
                            <button onClick={() => previewUrl(props.settings.styling.stylesheetUrl2)} className="btn btn-sm" type="button"><i className="fas fa-globe" /></button>
                        </div>
                    </div>
                </div>                
                </>
                }
            </>
    )
}

export { ThemesSiteStyling };
