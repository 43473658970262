import React from 'react';

function GlobalSettings(props) {

    function handleChange(e) {
        var { name, value } = e.target;

        if (e.target.type == "checkbox")
            value = e.target.checked;

        // Update parent's state
        props.onChange({[name]: value});
    }

    return (
        <>
            <h4 className="text-primary">Global</h4>
            <hr />
            <div className="mb-3">
                <label htmlFor="title" className="form-label">Title</label>
                <div className="input-group">
                    <input type="text" className="form-control" name="title" id="title" value={props.settings.title} disabled={!props.settings.showTitle} onChange={handleChange} />
                    <div className="input-group-text">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" name="showTitle" id="showTitle" checked={props.settings.showTitle} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="showTitle">Show</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="subtitle" className="form-label">Subtitle</label>
                <div className="input-group">
                    <input type="text" className="form-control" name="subtitle" id="subtitle" value={props.settings.subtitle} disabled={!props.settings.showSubtitle} onChange={handleChange} />
                    <div className="input-group-text">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" name="showSubtitle" id="showSubtitle" checked={props.settings.showSubtitle} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="showSubtitle">Show</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="instructionsText" className="form-label">Instructions</label>
                <textarea className="form-control" name="instructionsText" id="instructionsText" value={props.settings.instructionsText} onChange={handleChange} />
            </div>
        </>
    );
}

export { GlobalSettings }